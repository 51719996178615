@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-Light.woff);
  font-weight: 400;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-LightItalic.woff);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-MediumItalic.woff);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-Bold.woff);
  font-weight: 600;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-BoldItalic.woff);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-Extrabold.woff);
  font-weight: 800;
}

@font-face {
  font-family: HoneywellSansWeb;
  src: url(./fonts/HoneywellSansWeb-ExtraboldItalic.woff);
  font-weight: 800;
  font-style: italic;
}

:root {
  --conv-user-bg-color: #3799da;
  --conv-bot-bg-color: #ffffff;
  --btn-primary-active-color: #3799da;
  --btn-primary-hover-color: #2672b2;
  --btn-primary-pressed-color: #22608b;
  --btn-primary-inactive-color: #d7d7d7;
  --btn-secondary-active-color: #3799da;
  --btn-secondary-hover-color: #799db8;
  --btn-secondary-pressed-color: #22608b;
  --btn-secondary-inactive-color: #ededed;
}

html {
  height: 100%;
}

#root {
  margin: 0px 8px 8px 5px;
  position: relative;
 
}

#root > div {
  height: 100%;
}

* {
  box-sizing: border-box;
  /* overflow-x: hidden;
    overflow-y: hidden; */
}

.hide {
  display: none !important;
}

body {
  font-family: HoneywellSansWeb, sans-serif !important;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  /* width:"33vw"; */
  /* background-image:url(images/IMPartner.jpg);
    background-repeat: no-repeat;
    background-size: cover; */
}

.root {
  margin: 0px 5px 1px 5px;
  position: relative;
  /* width: 20%; */
}
.commentsContainer{
  flex: 0 1 100% !important;
}
.conversationTextBoxWelcomeMessage{
  max-width: 100% !important;
}
.commentsContainer textarea{
border: 1px solid rgb(0, 0, 0);
border-radius: 5px;
min-height: 5rem;
}
.privacyList{
  height: 100% !important;
}
.welcomePolicyDiv{
  height: 100%;
}
.welcomePolicyDiv .spinnerClose{
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.germanSection{
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.root-max {
  height: 95%;
  width: 95%;
  box-shadow: 0px 0px 13px 1px;
  animation: openUp 2s;
  border-radius: 10px;
  background: #fff;
  margin: 5px 5px;
}
.root-max *:not(button):not(button span):not(.send-box-container_HBT *):not(.welcomePolicySectionChina *){
  font-size: 14px !important;
}
.welcomePolicySectionChina *{
  font-size: 12px !important;
}
.suggesteActionButton{
  text-overflow: ellipsis;
  overflow: hidden;
 }
 .contentPolicy{
   text-align: justify;
 }
.nomarginpadding{
  margin: 0;
  padding: 0 !important;
}
.welcomePolicySection {
  bottom: 5px;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}
.policyButton button{
  margin: 10px !important;
  padding: 5px;
}
.policyButton{
 display: flex;
}
.contentPolicyGerman{
  width: 100%;
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 12px;
  font-weight: 600;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  text-align: justify;
}
@keyframes openUp {
  from {
    transform: scale(0.5);
    transform-origin:bottom right;
  }
  to {
    transform: scale(1);
  }
}
 .welcomeMessage p:first-child{
  margin:10px 0px 0px 0px !important;
}
.welcomeMessage p:last-child{
  margin:10px 0px !important;
}
.root-min {
  width: 33vw;
}

.liveAgentIcon {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 7px;
  color: white;
  background-color: #007BC2;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: HoneywellSansWeb, sans-serif !important;
}

/* Create two equal columns that floats next to each other */
/* .agentSystemMessagescolumn1 {
  height: 52px;
  float: left;
  width: 10%;
  padding: 10px;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
  background-image: url(../src/images/svgLatest/LiveAgentBlack.svg);
  background-position: right;
}
.agentSystemMessagescolumn2 {
  height: 52px;
  float: left;
  width: 90%;
  padding: 14px;
  text-align: left;
  font-weight: 700;
  background-color: #e6e6e6;
  font-size: small;
  color: #707070;
} */

/* Clear floats after the columns */
.agentSystemMessagesrow:after {
  content: "";
  display: table;
  clear: both;
}

#agentSystemMessage {
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  margin-left: 20px;
  line-height: 20px;
  color: #303030;
}

#agentSystemMessage hr {
  margin-left: -20px;
  border: 0.5px solid #d0d0d0;
}

#agentEndSystemMessage {
  text-align: left;
  font-weight: 700;
  font-size: small;
  color: #707070;
}
.Icon {
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
  background-image: url(./images/svgLatest/ConversationLogo.svg);
  color: #fff;
  text-align: center;
  font: 32px Arial, sans-serif;
}

/* @media only screen and (max-width: 1200px) {

    .root-max { 
      width: 35vw;
     
    }
  }

@media only screen and (max-width: 1100px) {

    .root-max { 
      width: 45vw;
     
    }
  }

@media only screen and (max-width: 1000px) {

    .root-max { 
      width: 55vw;
     
    }
  }

@media only screen and (max-width: 900px) {

    .root-max { 
      width: 65vw;
     
    }
  }

@media only screen and (max-width: 800px) {

    .root-max { 
      width: 75vw;
     
    }
  }

  @media only screen and (max-width: 700px) {

    .root-max { 
      width: 85vw;
     
    }
  }

@media only screen and (max-width: 600px) {

  .root-max { 
    width: 95vw;
   
  }
}
@media only screen and (max-width: 500px) {

    .root-max { 
      width: 100vw;
     
    }
  }

@media only screen and (max-width: 400px) {

    .root-max { 
      width: 100vw;
     
    }
  }

@media only screen and (max-width: 300px) {

  .root-max { 
    width: 100vw;
  }
} */

.btn-primary {
  background-color: #007BC2;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  min-height: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: HoneywellSansWeb, sans-serif !important;
}

.btn-primary:hover {
  background-color: var(--btn-primary-hover-color);
}

.btn-primary:active {
  background-color: var(--btn-primary-pressed-color);
}

.btn-primary:disabled {
  background-color: var(--btn-primary-inactive-color);
  cursor: not-allowed;
}

.btn-secondary {
  outline: none;
  background-color: #ffffff;
  color: #007BC2;
  border: 1px solid #007BC2;
  border-radius: 5px;
  cursor: pointer;
  min-height: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: HoneywellSansWeb, sans-serif !important;
}

.btn-secondary:hover {
  color:  #ffffff;
  border: 1px solid #ffffff;
  background-color: #007BC2;
}

/* .btn-secondary:active {
  color: #ffffff;
  outline: none;
  border: none;
  background-color: var(--btn-secondary-pressed-color);
}

.btn-secondary:disabled {
  color: var(--btn-secondary-inactive-color);
  border: 1px solid var(--btn-secondary-inactive-color);
} */

.main-max {
  /* border: 1px solid darkgrey; */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
}
#main>div{
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.main-min {
  border: none;
  display: block;
  animation: closeUp 2s;
}

@keyframes closeUp {
  from {
    transform: scale(0);
    transform-origin:top left;
  }
  to {
    transform: scale(1);
  }
}

.minmizedAvatarContainer {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}

.minimizeBotName {
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff;
  color: #303030;
  padding: 8px;
  margin: 0px !important;
  border-radius: 50px;
  border: 1px solid #707070;
  /* box-shadow: 0 0 0 0 rgba(107, 123, 134, 0), 0 0 0 2px rgba(14, 100, 161, 0.8); */
}

.HoneywellAssist {
  width: 60%;
  margin: 10px 5px 10px 30px !important;
  background-color: #1792e5;
  padding: 23px;
  border-radius: 50%;
}
.miniAvatarImgSize {
  width: 3rem;
  height: 3rem;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2px 0px 2px 16px;
  background-color: #007BC2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.menuContainer{
  position: absolute;
  background-color: #007BC2;
  z-index: 99;
  width: 99.9%;
  padding: 16px;
  animation: 1s appear forwards;
}
.agentBadge {
  background-color : #f7f7f7;
  border-bottom: 2px solid #d0d0d0;
  position: absolute;
  z-index: 99;
  width: calc(100% - 7px);
  padding: 5px 15px;
  animation: 1s appear forwards;
}
.menuContainer_HBT {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  animation: 1s appear forwards;
  border-radius: 8px;
  padding: 9px 20px;
  border: 1.5px solid #d0d0d0;
  bottom: 0;
}
.menuContainer_HBT > p {
  font-size: 13px !important;
  margin: 1rem 0;
  cursor: pointer;
}
.menuContainer_HBT > p:hover{
 color: #00629b;
}
.menuContainer_HBT > p > img {
  margin-right: 0.5rem !important;
  vertical-align: middle !important;
  width: 18px !important;
  margin-bottom: 0.15rem !important;
}

@keyframes appear {
  from {
    transform: scaleY(0.9);
    transform-origin: top;
  }
  to {
    transform: scaleY(1);
  }
}
.menuItem{
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  transition: color 0.5s ease-in-out;
  animation: 1s slideIn forwards;
}

@keyframes slideIn {
  from {
    transform: scaleY(0.2);
    transform-origin: left;
  }
  to {
    transform: scaleY(1);
  }
}

.menuItem:hover{
  color: #303030;
  cursor: pointer;
}

.ask-ellie {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  font-weight: 500;
}
.ask-ellie > h4 {
  display: inline;
  font-weight: bold;
  margin: 0px;
  padding-left: 8px;
}

.header > ul {
  display: flex;
  align-items: center;
  margin: 0px;
}

.header > ul > li {
  padding: 0px 15px 0px 0px;
}

.welcomecard {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: white;
  margin: -1px 0px;
  margin-bottom: 20px;
  padding: 6px 0 16px 12px;
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 500;
  background-color: #707070;
}

.welcome-image-container {
  position: absolute;
}

.orderSummaryButtons {
  font-size: 13px;
  font-family: HoneywellSansWeb, sans-serif !important;
  padding: 9px;
  font-weight: 500;
  font-style: normal;
  vertical-align: sub;
}

.orderSummary {
  text-align: center;
}

.orderSummaryCards {
  /* display: flex;
  flex-direction: column; */
  background-color: #ffffff;
  /* margin-left: 14px; */
  padding: 1rem 0;
}
.orderSummaryCards .row{
  padding-bottom: 0px !important;
}
.disclaimer-container {
  display: flex;
  flex-direction: row;
  border: 2px #f37021 solid;
  margin: 15px 25px;
  background-color: #ffffff;
}

.disclaimer-col-1 {
  margin: 0px;
  padding: 10px;
  padding-top: 12px;
  background-color: #f37021;
}

.disclaimer-col-2 {
  padding: 0px;
  margin: 5px;
}

.disclaimer-col-2 p {
  margin: 3px;
}

.disclaimer-header {
  font-size: 14px;
  font-weight: 700;
}

.disclaimer-sub-header {
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 360px) {
  .welcome-image-container {
    top: -10%;
  }
}

.welcomecard-header {
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin: 12px 0px 0px 0px;
}

.welcomecard-text {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  font-size: 12.5px;
  margin: 6px 0px;
}

.welcomecard-text > b {
  font-size: 13px;
}

.welcomecad-carousel {
  position: absolute;
  width: 100%;
  top: 95px;
}

.typingIndicator {
  display: flex;
  flex-direction: row;
  margin-bottom: 10%;
}

.dot {
  align-self: center;
  margin-left: 25px;
  margin-top: 8px;
}

.orderDetail {
  /* display: flex;
  margin: 0px 15px; */
}

.orderDetailHeader {
  background-color: #f7f7f7;
  text-align: left;
  padding: 1rem;
  border-radius: 8px 8px 0 0;
}

.orderDetailHeader > h6 {
  font-size: 11px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  margin: 0;
  /* text-transform: capitalize; */
}

.orderDetailHeader > div > b,
.orderDetailHeader > div > p {
  margin: 0;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  color: #303030;
  text-align: left;
  margin-right: 2px;
}

.orderDetailHeader > div > p {
  font-weight: 500;
}

.orderDetailBody {
  /* max-height: 223px; */
  overflow: auto;
  padding: 0 1rem;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.orderDetailBody > table {
  text-align: left;
  /* border-collapse: collapse; */
  table-layout: fixed;
  width: 100%;
}

.orderDetailBody > table > tbody > tr > td {
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  /* padding-bottom: 0.7rem; */
  line-height: 15px;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  /* padding: 0px 24px; */
  word-wrap: break-word;
  /* white-space: nowrap; */
  padding: 0.20rem 0;
}
.orderDetailBody > table > tbody > tr:first-child > td {
  /* padding-top: 24px; */
}
.orderDetailBody > table > tbody > tr:last-child > td {
  /* padding-bottom: 24px; */
  font-weight: 800;
}

.orderDetailBody > table > tbody > tr > td:first-child {
  color: #303030;
  font-weight: 700;
  display: flex;
  width: auto;
  padding-right: 0;
}

.orderDetailBody > table > tbody > tr > td:nth-child(2) {
  font-weight: 500;
  text-align: left;
  font-size: 11px;
  padding-left: 10px;
  /* border-left: 1px solid #e0e0e0; */
}

.orderDetailFooter {
  padding: 10px;
  border: 1px solid #3799da !important;
  background-color: #fff !important;
  color: #3799da !important;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  font-family: HoneywellSansWeb, sans-serif !important;
  width: 100%;
  align-items: center;
}

.viewMoreLineitems {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 16px 0;
  column-gap: 20px;
}

.card-warn-message {
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  padding: 5px 10px 5px 10px;
  margin: 0px;
  color: white;
  background-color: #404040;
}
.card-warn-message > div > p {
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
}

.heroCardContainer {
  display: flex;
  margin: 0px 15px 20px 15px;
}

.heroCard {
  border-radius: 8px 8px 8px 0px;
  box-shadow: 1px 1px 2px 0 #f7f7f7;
  background-color: #f7f7f7;
  color: #303030;
  margin: 0px 0px 0px 0px;
  width: 100%;
}

.heroCardTitle {
  font-size: 14px;
  padding: 12px 10px;
  font-weight: 600;
  color: #303030;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 5px;
}

.heroCardTitle > p {
  margin: 0;
  color: #303030;
}

.viewMore{
  color:  #ffffff !important;
  border: 1px solid #ffffff !important;
  background-color: #1792e5 !important ;

}
.heroCardButton {
  display: flex;
  flex-direction: column;
  outline: none;
  overflow: auto;
  max-width: 435px;
  border: 1.5px solid #d0d0d0;    
  border-radius: 0px 8px 8px 8px;
  /* max-height: 256px; */
}

.heroCardButton > button {
  /* margin: 0px 5px 6px 5px !important; */
  font-size: 13px;
}

.heroCardButton > div > button,
.heroCardButton > button,
.heroCardButton > a,
.heroCardButton > div > a> button{
  margin: 1px 0px 0px 0px;
  padding: 2px 0px 2px 15px;
  min-height: 35px;
  border: none;
  background-color: #f7f7f7;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  border-radius: 0px;
  text-align: left;
  cursor: pointer;
  font-family: HoneywellSansWeb, sans-serif !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.31);
  letter-spacing: normal;
  color: #007BC2;
}


.heroCardButton > div > button,.heroCardButton > div > a> button{
  color:  #007BC2 ;
  border: 1px solid #007BC2 ;
  text-align: left;
  min-width: 15%;
  margin: 10px 5px 6px 5px ;
  font-size: 10px;
  padding: 2px 10px 2px 10px;
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.31);
}


.heroCardButton > div > button:hover,
.heroCardButton > button:hover{
  color:  #ffffff;
  border: 1px solid #ffffff;
  background-color: #007BC2 ;

}

.extraButtons{
  text-align: left;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  outline: none;
  overflow: auto;   
  flex-wrap: wrap;
}
.extraButtons > div > button,
.extraButtons > button,
.extraButtons > a {
  flex: 0 48%;
  margin: 16px 0px 0px 0px;
  min-height: 35px;
  background-color: #f7f7f7;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  color: #007BC2;
  border: 1px solid #007BC2;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
}
.extraButtons > div > button:hover,
.extraButtons > button:hover
{
  color:  #ffffff;
  border: 1px solid #ffffff;
  background-color: #007BC2;
}
/* .heroCardButton > button:hover,
. heroCardButton > a:hover {
    color: #ffffff;
    background-color:  #202020;
} */

.LiveAgentImgPosition {
  background-color: #f7f7f7;
  position: relative;
}

.exitLiveChat {
  min-height: 35px;
  background-color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border-radius: 0px;
  text-align: center;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  color: #007BC2;
  border: 1px solid #007BC2;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 10px 10px 10px;
  padding: 10px 30px;
}

.exitLiveChat_HBT {
  min-height: 35px;
  border: 1px solid #007BC2;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 15px;
  margin-left: 2rem;
  margin-right: 6px;
}


.connectLiveagentText {
  text-align: center;
  color: #846f6f;
  font-weight: 500;
}

.exitLiveAdj{
  margin: 15px 10px 10px 10px !important;
}

.exitLiveChat:hover{
  color:  #ffffff;
  border: 1px solid #ffffff;
  background-color: #007BC2;
}
.webChat {
  font-family: HoneywellSansWeb, sans-serif;
  background-color: #ffffff;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  border-top-left-radius:9px;
  border-top-right-radius:9px;
}

.webChatScroll{
  overflow-x: hidden;
  overflow-y: auto;
}

.webChat > ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-bottom: 10px;
  overflow-anchor: auto;
}

.webChat > ul > li:last-child:not(:first-child) > div > div {
  animation: bublecon 0.8s ease-in-out;
}

.webChat > ul > li:last-child:not(:first-child) > ul > li > div {
  animation: bublecon 0.8s ease-in-out;
}

.webChat > ul > li:last-child:not(:first-child) > div > ul {
  animation: bublecon 0.8s ease-in-out;
}

.webChat > ul > li {
  overflow-anchor: auto;
  margin: 13px 0px 0px 0px;
}

.webChat > ul > li > div {
  padding: 0px 15px 0px 15px;
}

.webChat > ul > li > ul > li > div {
  padding: 0px 15px 0px 15px;
}

.webChat > ul > li > ul > li > div > p {
  width: 100%;
}

.activityClass {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.botActivityClass {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.userActivityClass {
  justify-content: flex-end;
}
.root-max {
  container-type: inline-size; /* Enable container queries on this element */
}
.conversationTextBox {
  max-width: 310px;
  padding: 10px 17px 10px 17px;
  margin-left: 0px;
  margin-right: 0px;
}
@container (min-width: 430px) {
  .conversationTextBox {
    max-width: 500px;
  }
}
@container (max-width: 429px) {
  .conversationTextBox {
    max-width: 310px;
  }
}

.conversationTextBox > p {
  padding: 0;
  margin: 0;
}
.botTextBox {
  border-radius: 8px 8px 8px 0px;
  /* box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.31); */
  background-color: #f7f7f7;
  color: #303030;
  border: 1.5px solid #d0d0d0;
}

.userTextBox {
  text-align: right;
  border-radius: 8px 8px 0px 8px;
  box-shadow: 1px 1px 2px 0 #007BC2;
  background-color: #007BC2;
  color: #ffffff;
}
.conv-text p{
  font-weight: 500;
}
.conv-text {
  font-size: 13px; 
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  word-wrap: break-word; 
}

.displayNone {
  display: none !important;
}

.conv-text > p {
  margin: 0;
  font-size: 13px; 
  font-weight: 500;
}

.conv-text > div p {
  margin: 0;
}

.botAvatarClass {
  margin-top: 6px;
}

.botAvatar.botAvatarAgent {
}

.botTimestamp {
  margin: 2px 8px;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: normal;
  color: #a5a5a5;
}

.botTimestampUser {
  text-align: right;
}

.botTimestampBot {
  text-align: left;
}

.agentTimestamp {
  margin: 2px 8px;
  margin-left: 14px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: normal;
  color: #a5a5a5;
}

.agentTimestampUser {
  text-align: right;
}

.agentTimestampBot {
  text-align: left;
}

/* .agentLabel {
  text-align: left;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
}

.agentLabel.agentLabelAgent {
  display: none;
  color: #0063B1;

}

.agentLabel.agentLabelUser {
  display: none;
} */

.sendRetry {
  font-family: HoneywellSansWeb, sans-serif;
  margin: 2px 8px;
  margin-left: 14px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: normal;
  color: #303030;
  visibility: hidden;
  text-align: right;
  display: none;
}

.suggestedActionHeader {
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin: 10px 0px 0px 0px;
}

.suggestedActionText {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin: 5px 0px;
}

.suggestedAction {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.suggestedaction-helpul {
  background-image: url(./images/svgLatest/Like.svg);
  background-repeat: no-repeat;
  background-size: 25px;
  height: 25px;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 10px 15px !important;
  outline: none;
  border: none !important;
  margin: 1rem 0 0 3.5rem !important;
  flex: none !important;
  flex-grow: 1 !important;
}
.suggestedaction-helpul:hover{
  border: none !important;
  background-color: transparent !important;
}
.suggestedaction-nothelpul {
  background-image: url(./images/svgLatest/DisLike.svg);
  background-repeat: no-repeat;
  background-size: 25px;
  height: 25px;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 10px 15px !important;
  outline: none;
  border: none !important;
  margin: 1rem 0 0 2rem !important;
  flex-grow: 4 !important;
}
.suggestedaction-nothelpul:hover{
  border: none !important;
  background-color: transparent !important;
}

.suggestedAction > li {
  margin-right: 12px;
  margin-bottom: 12px;
}
.suggesteActionButton {
  flex: 0 48%;
  margin: 16px 0px 0px 0px;
  min-height: 35px;
  background-color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  border-radius: 5px;
  text-align: center;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  color: #007BC2;
  border: 1px solid #007BC2;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.suggesteActionPrimaryButton {
  border: .0625rem solid #1274b7;
  background-color: #1274b7;
  color: #fff;
}

.suggesteActionButton:hover {
  color:  #ffffff;
  border: 1px solid #ffffff;
  background-color: #007BC2;
}

ul {
  list-style: none;
  padding: 0;
}

.send-box-container {
  display: flex;
  flex-wrap: wrap;
  border-top: 1.5px solid #d0d0d0;
  background-color: #d0d0d0;
}

.send-box-container_HBT {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0;
}

.send-box {
  flex-grow: 6;
  flex-shrink: 4;
  border-radius: 4px;
}

.send-box-button {
  flex-grow: 0;
  flex-shrink: 3;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  background-image: url(./images/svgLatest/Enter.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-size: 20px;
  border: none;
  color: white;
  padding: 20px 15px 20px 24px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  height:48px;
  border-radius: 0 4px 4px 0;
}

.send-box-button:hover {
  background-color: var(--btn-primary-hover-color);
  border: 1px solid var(--btn-secondary-hover-color);
  background-image: url(./images/svg/Send_icon_pressed.svg);
  background-size: 26px;
}

.send-box-button:active {
  background-color: var(--btn-primary-active-color);
  border: 1px solid var(--btn-secondary-pressed-color);
  background-image: url(./images/svg/Send_icon_pressed.svg);
  background-size: 26px;
}

.slide {
  background-color: transparent !important;
}

.selected {
  margin: 20px;
}

.slide > div {
  box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin: 0px 17px;
}

.send-box-input {
  border: none;
  padding: 20px 10px;
  font-size: 14px;
  width: -webkit-fill-available;
  display: block;
  outline: none;
  font-family: HoneywellSansWeb, sans-serif;
  height: 48px;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 4px 0 0 4px;
}

.disableSendBox{
  pointer-events: none !important;
  background-color: #c0c0c0 !important;
}

.carouselLefttArrow {
  background-image: url("./images/svg/Carousel_left _arrow.svg");
  background-size: cover;
  background-color: transparent;
  outline: none;
}

.carouselRightArrow {
  background-image: url("./images/svg/Carousel_right _arrow.svg");
  background-size: cover;
  background-color: transparent;
  outline: none;
}

.carousel-body-size {
  width: 100%;
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
  border-radius: 8px;
  /* margin-bottom: 1rem; */
}

.footer {
  height: 33px;
  background-color: #ffffff;
  padding: 1px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.footer > h2 {
  margin: 3px;
  text-align: end;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #707070;
  padding-right: 12px;
  padding-top: 5px;
}
.footerLogo {
  height: 55%;
  float: right;
  margin-right: 22px;
  margin-top: 6px;
  padding-top: 2px;
}

.surveyCardContainer {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  background-color: #f7f7f7;
  margin: 0;
}

.skipSurveyLink {
  flex: 0 46%;
  margin: 1.5rem 0 0 0;
  min-height: 35px;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  padding: 8px 10px;
  cursor: pointer;
  font-family: HoneywellSansWeb, sans-serif;
  font-weight: 700;
  border-radius: 0px;
  outline: none;
}

.surveyCardImage {
  z-index: 1;
  height: 35%;
}

.surveyCard {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 0;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.31);
  background-color: #ffffff;
  width: 90%;
  border-radius: 5px;
}

.surveyCard > h1 {
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #303030;
  margin: 20px 20px 10px 20px;
}

.surveyCard > p {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  margin: 10px 20px 10px 20px;
}

.surveyCard > div > textarea {
  position: relative;
  z-index: 100;
  width: -webkit-fill-available;
  height: 90px;
  font-family: HoneywellSansWeb, sans-serif;
  font-weight: 500;
  font-size: 15px;
  border: solid 1.5px #d0d0d0;
  background-color: #ffffff;
  margin: 10px 20px 0px 20px;
}

.surveyCard > button {
  padding: 16px;
}
.surveyCard_Aero {
  position: absolute;
  top: 25%;
  left: 5%;
  z-index: 0;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.31);
  background-color: #ffffff;
  width: 90%;
  border-radius: 5px;
}
.surveyCard_Aero > p {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  margin: 10px 20px 10px 20px;
}
.sureveyCardButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem;
  justify-content: space-between;
}

.sureveyCardButtons > button{
  border-radius: 4px;
}

.ReactModal__Overlay {
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45) !important;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.ReactModal__Content {
  z-index: 2;
}

#main2 {
  width: 90%;
  overflow-x: hidden;
}

.restartHeader {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #303030;
  margin: 0px;
  margin-bottom: 25px;
}

.restartParagraph {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  margin: 5px 0px;
}

.restartYesButton {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  padding: 15px 48px;
  cursor: pointer;
  border-radius: 3px !important;
}

.restartButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.restartNoButton {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  padding: 15px 48px;
  margin: 12px;
  cursor: pointer;
  border-radius: 3px !important;
}



.erroHandler {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  color: red;
  text-align: center;
  background-color: #f7f7f7;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.spinnerClass {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 100px;
}

.spinnergif {
  position: relative;
  transform: translateY(-22%);
}

.spinnergif > img {
  position: relative;
  width: 176px;
  height: 176px;
  margin: 0 auto;
  display: block;
}

.spinner-text {
  position: relative;
  bottom: 12px;
  left: 12px;
  font-weight: 500;
  text-align: center;
  /* color: #999999; */
  font-size: 14px;
  width: 100%;
}

.homeContainerButtonXSBG {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

.customscrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  background-color: transparent;
  border-radius: 0px;
}

.customscrollbar::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
  background-color: transparent;
}

.customscrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #adadad;
  height: 20px;
}

.markdown-link {
  color: #1792e5;
  font-size: 14px;
  text-decoration: none;
}
.markdown-link::before {
  content: "" !important  ;
}

.markdown-link::after {
  content: "" !important;
}
.markdown-link-footer {
  color: #45a8ea;
  font-size: 14px;
  text-decoration: none;
}
.markdown-link-footer::before {
  content: "" !important  ;
}

.markdown-link-footer::after {
  content: "" !important;
}

.markdown-list {
  margin: 12px 0px;
  padding: 5px;
  min-height: 45px;
  border: solid 1px #d0d0d0;
  background-color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: normal;
  border-radius: 4px;
  text-align: justify;
  color: #707070;
}

.viewMoreLink {
  /*  margin-bottom: 20px; */
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  color: #fff !important; 
  cursor: pointer;
  min-height: 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: HoneywellSansWeb, sans-serif !important;
  padding: 0 1rem;
}
.viewMoreLink > img{
  margin-right: 5px;
  /* width: 10px; */
}
.viewMoreLink > p{
  color: #fff;
  font-weight: 700;
  font-size: 12px;
}

.viewMoreLink:hover {
  background-color: var(--btn-primary-hover-color) !important;
}

.viewLineLink {
  flex: 63%;
  text-align: center;
  outline: none;
  cursor: pointer;
  min-height: 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: HoneywellSansWeb, sans-serif !important;
  border-radius: 6px;
  /* margin:16px 0; */
  color: #fff;
  padding: 0 1rem;
}

.viewLineLink:disabled {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
  cursor:not-allowed;
}

#qnaSuggestionList:disabled {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
  cursor:not-allowed;
  border-bottom: 1px solid #fff;
}

.viewLineLink:disabled:hover {
  color: #fff;
  background-color: #a0a0a0;
  border-color: #a0a0a0;
}

.goToOrderDetails{
  flex : auto;
  border-radius: 6px !important;

}
.ratingContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-around;
}

.ratingContainer > li > button {
  height: 40px;
  width: 40px;
  border: none;
  background-color: white;
  outline: none;
}

#article {
  margin: 0% 15%;
  padding: 10px 20px !important;
  border-radius: 6px;
}

.article-button-container {
  margin: 0px 10px 0px 18px;
  width: 100%;
}

.article-button {
  font-family: HoneywellSansWeb, sans-serif;
  padding: 15px;
  width: 100%;
  font-weight: 600;
}

.article-popup-container {
  margin: 20px 50px 20px 20px;
  position: relative;
}

.pop-up-close-container {
  position: sticky;
  top: 0px;
  right: 0px;
  text-align: end;
}

.article-popup-container > div {
  border-bottom: 1px solid rgb(145, 145, 145);
  padding-bottom: 10px;
  display: block;
  margin-bottom: 15px;
  color: rgb(105, 105, 105);
  font-size: 14px;
  font-weight: 500;
  font-family: HoneywellSansWeb, sans-serif !important;
}

.article-popup-container > div > h1 {
  color: rgb(145, 145, 145);
  font-size: 1em;
}

.article-popup-container > div > span:first-child {
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  color: rgb(105, 105, 105);
  margin-bottom: 20px;
}

.article-popup-container > div > span {
  font-family: HoneywellSansWeb, sans-serif !important;
  color: rgb(105, 105, 105);
}

.article-popup-container > div > ul {
  list-style: disc;
  color: rgb(105, 105, 105);
  padding-left: 28px;
}
.article-popup-container > div > ul > li {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}

.article-popup-close {
  position: sticky;
  top: 0;
  padding: 5px;
  cursor: pointer;
  font-weight: 600;
}

.article-popup-container > div > ol {
  font-weight: 500;
  font-size: 14px !important;
  color: rgb(105, 105, 105);
  font-family: HoneywellSansWeb, sans-serif !important;
}

a {
  font-weight: 600;
  text-decoration: none;
  color: var(--conv-user-bg-color);
  cursor: pointer;
}

.suggestedInputContainer {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  bottom: 100%;
  border: 1.5px solid #d0d0d0;
  border-radius: 8px 8px 0 0;
  border-bottom: none;
  left: 0;
}

.suggestedInputContainer > ul {
  margin: 0;
}

.suggestedInputButton {
  display: flex;
  background-color: transparent;
  border: none;
  border-bottom: 1px #e8e8e8 solid;
  outline: none;
  padding: 5px;
  width: 100%;
  align-items: flex-start;
}

.suggestedInputButton:hover {
  background-color: #eee;
}

button:active {
  outline: none;
  border: none;
}

.suggestedInputImage {
  height: 16px;
  width: 16px;
  margin: 0px;
  background-image: url(./images//search.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.suggestedInputData {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  font-family: HoneywellSansWeb, sans-serif;
  text-align: left;
  color: #505050;
  margin: 0px 0px 0px 0px;
  width: calc(100% - 16px);
  padding-left: 8px;

}

.verySad {
  background-image: url(./images/FB_Very_Sad.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.presseVerySad {
  background-image: url(./images/FB_Very_Sad_Pressed.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.sad {
  background-image: url(./images/FB_Sad.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.pressedSad {
  background-image: url(./images/FB_Sad_Pressed.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.nuetral {
  background-image: url(./images/FB_Neutral.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.pressedNuetral {
  background-image: url(./images/FB_Neutral_Pressed.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.happy {
  background-image: url(./images/FB_Happy.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.pressedHappy {
  background-image: url(./images/FB_Happy_Pressed.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.veryHappy {
  background-image: url(./images/FB_Very_Happy.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.pressedVeryHappy {
  background-image: url(./images/FB_Very_Happy_Pressed.png);
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  cursor: pointer;
}

@keyframes Notification-Blink  {
  0%, 49% {
    border: 1.5px solid #007BC2;
  }
  50%, 100% {
    border: 1.5px solid #f7f7f7;
  }
}

.notificationAnimation {
  animation: Notification-Blink 2s 7,
             createBox 2s !important; 

}

.launcherIcon{
  text-align: left;
  box-shadow: 0px 0px 2px 0px rgb(64 65 65 / 60%), 0px 0px 40px 0px rgb(105 105 105 / 30%);
  margin-right: 25px;
  padding: 25px 20px 0px 20px;
  margin-bottom: 50px;
  background-color: #f7f7f7;
  animation: createBox 2s;
}
@keyframes createBox {
  from {
    transform: scale(0.2);
    transform-origin:bottom right;
  }
  to {
    transform: scale(1);
  }
}

.launcherIcon>span, 
.launcherIcon>p{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #303030;
  font-size: 12px;
  font-weight: 500;
}

.lightText{
color: rgb(112, 112, 112)  !important;
font-size: 10px !important;
font-weight: 600 !important;
margin-top: 10px !important;
margin-bottom: 10px !important;
}

.chatNowButton {
  font-size: 12px;
  box-shadow: 0px 0px 2px 0px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  background-color: #fff;
  padding: 6px 12px;
  cursor: pointer;
  font-family: HoneywellSansWeb, sans-serif;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  border: 1px solid #007BC2;
  color: #007BC2;
}
.chatNowButton:hover{
color:  #ffffff;
border: 1px solid #ffffff;
background-color: #007BC2;
}

.launcherIconHoneywellAssit {
  width: 3.8rem;
  height: 3.8rem;
  text-align: center;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 50%;
  margin-left: 35%;
  margin-top: -30%;
  border: 1.5px solid #d0d0d0;
  position: relative;
  animation: mymove 3s;
  animation-iteration-count: 3;
  animation-direction: alternate;
}

.botIconAnimation {
  animation: botmove 2s 7 !important; 
}

.vertical {
  border-left: 1.5px solid #45A8EA;
  height: 3.5rem;
  margin-left: 15px;
  margin-right: 12px;
}

@keyframes mymove {
  from {left: -80px;}
  to {left: 80px;}
}

@keyframes botmove {
  from {top: -20px;}
  to {top: 5px;}
}

.launcherIconCloseAssist {
  width: 35%;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
  margin-left: 35%;
  margin-top: -30%;
  height: 30%;
}
.maximizeHeaderIcon{
  width: 2rem;
  height: 2rem;
  padding: 3px;
  margin-bottom: -15%;
  margin-left: 20%;
  border-radius: 50%;
  cursor: pointer;
  filter: brightness(0) invert(1);
}
.launcherIconAvatar{
  width: 2rem;
  height: 2rem;
  margin-right: 0.05rem;
  margin-top: 0.28rem;
}
.launcherIconClose{
  width: 3rem;
  height: 3rem;
  margin-bottom: -25%;
  margin-left: 85%;
  border-radius: 50%;
  cursor: pointer;
}

.minimizedLauncherIcon{
  height: 5rem;
  width: 5rem;
  margin-bottom: 0px;
  cursor: pointer;
  /* animation: fadeOut 2s 2s; */
}
.minimizedLauncherIcon_HBT {
  display: block;
  margin-right: 0.3rem;
  height: 4.5rem;
  width: 4.5rem;
  margin-bottom: 2px;
  cursor: pointer;
}
.launcherBlock{
  display: block !important;
}
.minimizedLauncherIcon_NewHBT {
  display: none; 
  margin-right: 0.3rem;
  width: 14rem;
  margin-bottom: 2px;
  cursor: pointer;
  animation:  0.2s ease-out reverse rise;
}

@keyframes rise {
  from {
    transform: translateX(50px);
  }
  to {
    transform: translateX(100px);
  }
}

.logoOverflow {
  overflow: hidden;
}

@keyframes fadeOut {
  from {
    transform: scale(1);
    transform-origin:top left;
  }
  to {
    transform: scale(0.2);
  }
}

@keyframes bublecon {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* Edge support */

@supports (-ms-ime-align: auto) {
  .send-box-input {
    width: 100%;
  }
  .surveyCard > button,
  .surveyCard > div > textarea {
    width: 90%;
  }

  .footer > h2 {
    text-align: right;
  }
}

/* ie11 support */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  html {
    height: 99.6%;
  }
  .css-14smvh1.webchat__initialsAvatar:not(.webchat__initialsAvatar--fromUser),
  [data-css-14smvh1].webchat__initialsAvatar:not(.webchat__initialsAvatar--fromUser) {
    background-color: #0063b1;
  }
  #root {
    margin: 0px 18px 1px 5px;
  }

  .send-box-input {
    width: 100%;
  }
  .surveyCard > button,
  .surveyCard > div > textarea {
    width: 90%;
  }

  .footer {
    padding: 0px;
  }

  .footer > h2 {
    text-align: right;
  }

  .surveyCardContainer {
    flex-direction: row-reverse !important;
  }

  .suggestedInputContainer {
    position: fixed;
    margin-bottom: 95px;
  }
}

/* Mozilla Firefox support */

@supports (-moz-appearance: none) {
  .send-box-input {
    width: -moz-available;
  }

  .orderDetailBody > table {
    width: auto;
  }

  #web-chat {
    scrollbar-color: #adadad transparent;
    scrollbar-width: thin;
    scroll-behavior: revert;
  }
}

@-moz-document url-prefix() {
  .surveyCard > div > textarea {
    width: -moz-available;
  }
}
.timer {
margin-left: 10px;
font-size: 18px;
font-weight: 600;
color: #303030;
}

.timer_HBT {
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  position: relative;
  right: 9px;
  }
.userCountText { 
  margin-top: 10px;
  font-size: 14px;
  color:#707070;
  font-weight: 600;
}
.userCountText_HBT { 
  font-size: 13px;
  font-weight: 500;
}
.viewFullArticle {
  padding: 15px;
  font-size: 14px;
  Font-weight: 600;
  background-color: #1792E5;
}

.viewFullArticle:hover {
  background-color: #1272B2;
}

.documentDownload{
 padding: 0px 10px;
 margin-top: 10px;
}

.documentDownload > span > a {
  white-space: nowrap;
}

.setMatrixSurvey{
  height: 100%;
  width: 100%;
  border: 0px none !important;
}

.agentIcon{
  padding: 0px 15px 0px 15px;
}

.agentIcon .botActivityClass > div {
  position: relative;
  margin-left: 5px;
}


.holds-the-iframe {
  background:url(./images/Spinner.gif) center center no-repeat;
  width: 100%;
  height: 100%;
}

.documentWithoutAttachment{
  color: black;
  font-weight: 600;
  width: auto;
  padding-right: 0;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  padding-bottom: 0.7rem;
}

.homeContainer{
  padding: 5%;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.closeBot {
  width: 12px !important;
}

.closeBotDiv {
  cursor: pointer;
  color: #3799da;
  text-align: end;
}

.homeContainer > div > a{
  float: right;
}

.homeContainer > div > a >img{
  width: 70%;
}
.homeContainer > div > img{
  width: 12%;
}

.homeContainer > div > div{
text-align: left;
}

.homeContainer > div > button{
  /* display: block; */
  width: 100%;
  margin: 7px 0px 7px 0px;
  }
  .homeContainer > div > button:hover{
  color:  #ffffff;
  border: 1px solid #ffffff;
  background-color: #007BC2;
  }

[data-title]:hover:after {
  opacity: 1;
  transition-delay: 100s;
  transition-duration: 200s;
  transition-timing-function: ease-in-out;
  display: block;
}

[data-title]:after {
  content: attr(data-title);
  border: #666 2px dotted;
  padding: 5px;
  display: none;
  z-index: 999;
  background: #d0d0d0;
  position: sticky;
  text-decoration: none;
  color: #f15a4f;
  margin-top: 0.5rem;
}

.homeContainerFixed { 
  position:sticky;
  text-align:center;
  margin-top: 1%;
}

.marginHomeButtons {
  margin-bottom: 6rem !important;
}

.homeContainerButtons { 
  position:sticky;
  text-align:center;
  left:5%;
  right:5%;
  padding-right: 0.5rem;
  overflow-y: auto;
  min-height: 180px;
}

.homeContainerButtonsHBT { 
  position:sticky;
  text-align:center;
  left:5%;
  right:5%;
  padding-right: 0.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sendBoxInputHBT {
 border: 1px solid #d0d0d0;
 background-color: #d0d0d0 !important;
}
.margin_5{
  margin: 5px 0 0 0 !important;
}
.WelcomeButton:not(:last-of-type) {
  margin-right: 12px !important
}

p{
  color: #303030;
  font-size:12px;
  font-weight: 600;
}

.alignCenter{
  margin-top:200px;
}


.row {
  position: relative;
  border: 1px solid #d0d0d0;
  padding: 8px 8px 24px 8px;
  background-color: #F7F7F7;
  border-radius: 8px 8px 8px 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  column-gap: 8px;
  flex-direction: column;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row .resultCounter{
  margin: 12px 32px;
  color: #303030;
  font-size: 12px;
  font-weight: 500;
}

.row .rowContent{
   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 8px;
}

.column {
  float: left;
}

.left, .right {
  /* width: 5%; */
  transform: translateY(-50%);
  top: 50%;
  /* position: absolute; */
}

.left{
  left: 0;
  /* margin-right: 1rem; */
}

.right{
  right: 0;
  /* margin-left: 1rem; */
}
.center {
  width: 100%;
  /* margin-left: 9%; */
}

.spinnerClose{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.spinnerClose > div > a{
    float: right;
    margin-right: 20px;
    margin-top: 20px;
}
.spinnerClose > div > a > img{
  width: 70%;
}

.hbtMargin{
margin: 0%;
border-radius: 4px 0 0 4px;

}

.hbtLauncherIconPadding{
  padding: 30px 20px 10px !important;
}

.case-webform {
  font-weight: 700 !important;
  font-size: 13px !important;
}

.webformActions {
  border: none;
  padding: 1rem 0 0 0;
  background: none;
  display: flex;
  justify-content: flex-end;
  text-align: left;
}

.webformActions > button {
  margin-left: 1.5rem;
  border-radius: 4px;
}

.webformButton {
  outline: none;
  border-radius: 0;
  transition: border-color .5s ease,background .5s ease,color .5s ease;
  box-sizing: border-box;
  text-transform: uppercase;
  font-family: HoneywellSansWeb, sans-serif !important;
  font-weight: 600;
  padding: .6875rem 2.9375rem;
  margin: 0;
  font-size: 1rem;
  letter-spacing: .5px;
  line-height: 1.5rem;
}

.webformButton.primary {
  border: .0625rem solid #1274b7;
  background-color: #1274b7;
  color: #fff;
  border-radius: 4px;
}

.webformButton.secondary { 
  border: .0625rem solid #1274b7;
  color: #1274b7;
  background-color: initial;
}

.webformFieldTitle {
  font-family: HoneywellSansWeb, sans-serif !important;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #303030;
  font-weight: 700;
}

.kBFields {
  font-size: 0.85rem !important;
  line-height: 1rem !important;
  margin-top: 0.25rem;
}

.kBFieldSelect {
  font-size: 0.85rem !important;
  height: 2rem !important;
  margin-bottom: 0.7rem !important;
}

select.kBFieldSelect > option {
  font-size: 0.85rem !important;
  height: 1rem;
  cursor: pointer;
}
.kbFormCustomMarginLA{
  margin-left:0.1rem !important; 
  margin-right: 1.4rem !important;
}
.kbFormCustomMargin{
  margin-left:1.3rem !important; 
  margin-right: 1.4rem !important;
}
.kBForm {
  width: 80%;
  border-radius: 0px 8px 8px 8px;
  background-color: var(--conv-bot-bg-color);
  color: #303030;
  border: 1.5px solid #d0d0d0;
  padding: 10px 17px 10px 17px;
  margin: 0.1rem 0.5rem 0.1rem 4.1rem;
  max-width: 310px;
}

.kBFormSubmit {
  padding: 0.4rem 1.5rem !important;
  float: right;
}

.kBFormSubmitDiv {
  height: 2rem;
}
.webformFieldCommentsTitle {
  font-family: HoneywellSansWeb, sans-serif !important;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #303030;
  font-weight: 700;
}

.webformFieldCommentsTitle:after {
  color: #f15a4f;
  content: " *";
  display: inline;
}

.webformContainer {
  display: flex;
  flex-direction: row;
  max-height: 55vh;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.webformContainer > div {
  flex: 0 48%;
  margin-bottom: 1rem;
  padding-right: 30px;
}

.webformBadgeCircular {
  background-color: #be271c!important;
  border-color: #be271c!important;
  color: #fff!important;
  width: .5rem!important;
  height: .5rem!important;
  padding: 0!important;
  min-width: 0!important;
  min-height: 0!important;
  text-align: center;
  border-radius: 500rem;
  align-self: flex-end;
  margin-left: .25rem;
  margin-bottom: .5rem!important;
}

.webformInputLabel {
  margin-bottom: .25rem;
  display: flex;
}

.input-wrap {
  width: 100%;
}

.input-disabled {
  cursor: not-allowed!important;
  background: #f7f7f7;
  color: silver!important;
}

.input-wrap-text {
  font-family: HoneywellSansWeb, sans-serif !important;
  letter-spacing: 0;
  font-weight: 500;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  border: .0625rem solid #d0d0d0;
  border-radius: 4px;
  color: #303030;
  height: 3rem;
  transition: border-color .15s ease-in;
  box-sizing: border-box;
  padding: 0rem .6875rem;
  /* z-index: 1; */
  position: relative;
  background: transparent !important;
}
.input-wrap-text-plugin {
  font-family: HoneywellSansWeb, sans-serif !important;
  letter-spacing: 0;
  font-weight: 500;
  background: transparent !important;
}

select.input-wrap-text > option {
  font-weight: 500 !important;
}
.webform-popup-close-container {
  top: 0px;
  right: 0px;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-wrap-text::placeholder {
  color: #d0d0d0;
  font-size: 14px;
  font-style: italic;
}

.PhoneInputInput {
  height: 100%;
  border: none;
  font-family: HoneywellSansWeb, sans-serif !important;
  font-weight: 500;
  font-size: 1rem;
}

.PhoneInputCountrySelect {
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 1rem;
}

.react-datetime-picker__wrapper {
  border: none;
}

.PhoneInputCountrySelect > option{
  font-family: HoneywellSansWeb, sans-serif !important;
  font-weight: 500;
  font-size: 1rem;
}

.webformButton.primary:disabled {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
}
.createLead { 
  width: 90%;
  height: 2.5rem;
  font-family:  HoneywellSansWeb, sans-serif !important;
  font-weight: 500;
  cursor: pointer;
}
.createLead:focus {
  outline-color: #45a7ea !important;
}
.createLeadOptions {
  font-family: HoneywellSansWeb, sans-serif !important;
  font-weight: 500;
  cursor: pointer;
}
.createLead option {
  height: 3rem;
  cursor: pointer;
  color: #303030;
  font-size: 16px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.createLeadLabel {
  font-weight: 700;
  font-size: 13px;
}
.policySection {
  position: absolute;
  bottom: 5px;
  left: 5%;
  right: 5%;
  width: 90%;
  margin: 0;
  padding: 0;
}
.contentPolicy {
  width: 80%;
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 12px;
  font-weight: 600;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  color: #007BC2;
  text-align: justify;
}
.contentPolicy * ,.policyButton *{
  font-size: 12px !important;
  margin: 0px !important;
 }
.contentPolicyHBT {
  font-size: 13px;
  font-weight: 500;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  text-align: justify;
}

.workflowImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content:space-evenly;
}

.figureStyle {
  flex: 0 33%;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.figureImage {
  border-radius: 10%;
}

.figureImage:hover {
  border: 1.5px solid #007BC2;
}

.figureCaption {
  font-size: 13px;
  font-weight: 600;
  font-family: HoneywellSansWeb, sans-serif !important;
  margin-bottom: 1rem;
  width: 100px;
}

.imageBorder{
  margin-left: 0px;
  margin-right: 4px;
  padding: 5px;
}

.imageSize {
  width: 100%;
  height: 100%;
}

.workflowButtonTextBox {
  background-color: #f7f7f7;
}

.accessDeny {
  margin-top: 4rem;
  font-size: 16px;
  font-weight: 500;
  font-family: HoneywellSansWeb, sans-serif !important;
  outline: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.accessDenyText {
  padding: 10px;
}

.accessDenyBotImg {
  flex: 0 40%;
}

.closeBotDivMargin {
  margin: 1rem;
}

.closeBotIcon {
  width: 16px !important;
}

.OptInfoTooltip {
  cursor: pointer;
}

.botDivHeight {
  height: auto; 
}


.welcomecardBotDivHeight { 
  height: 100%;
}
li.botDivHeight:has(>ul.botDivHeight):has(li.botDivHeight:empty){
  display: none !important;
}
.mktoCheckboxList input {
  opacity: 1 !important;
}

.below-text {
  min-height: 1.25rem;
  margin: 0.12rem 0;
}

.below-text .ui.helper-message {
  font-family: HoneywellSansWeb, sans-serif !important;
  letter-spacing: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.25rem;
  display: block;
  color: #a0a0a0;
}
.helpVideoLink {
  color: #fff;
  font-size: 18px;
}

.HBTLiveagentTextAlign {
  text-align: left;
}

.liveagentTextAlign {
  text-align: left;
  padding-top: 2rem;
}

.HBTLiveagentFont {
  font-weight: bold;
}

.HBTLiveagentMargin {
  margin-top: 1.5rem;
  font-weight: bold;
}

.LiveAgentUnavailable:disabled {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
  cursor:not-allowed;
}

.LiveAgentUnavailable:disabled:hover {
  color: #fff;
  background-color: #a0a0a0;
  border-color: #a0a0a0;
}

.css-df17o1 {
  position: fixed !important;
  background: rgba(0, 0, 0, 0.4) !important;
} 

.goToSurvey:disabled {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
  cursor:not-allowed;
}
.goToSurvey:disabled:hover {
  color: #fff;
  background-color: #a0a0a0;
  border-color: #a0a0a0;
}
.addIcon {
  vertical-align: bottom;
  margin-left: 0.5rem;
}
.hpsModal{
  height:auto;
  background-color: white;
}
.hpsModal_default{
  height:auto;
  background-color: white;
}
.sendBoxMenuIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  margin-top: 12px;
}
.menuIcon {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.sendBox_HBT {
  flex-grow: 7;
  border: 1.5px solid #d0d0d0;
  margin-right: 21px;
  border-radius: 4px;
  padding: 0;
  position: relative;
}
.restartNew_HBT {
  font-size: 12px !important;
  padding: 9px 25px !important;
}
.restartHeader_HBT {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 0.3rem;
}
.restartText_HBT {
  margin-bottom: 1rem;
  font-size: 14px;
  margin-left: 2rem;
  margin-top: 1rem;
}
.FAQindent{
  /* margin: 10px 0.6rem 10px 3.3rem; */
  margin-bottom: 12px
}
.iconContainerMain{
  display: flex;
  align-items: center;
}
.iconContainer {
  padding:10px;
  position: absolute;
  right: 10px;
}
.displayFaqFlex{
  display: flex;
  border: 1px solid #007BC2;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 6px;
}
.bgBlue{
  background-color: #007BC2 !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px
}
.bgNone{
  background-color: #fff !important;
}
.linkShow{
  font-family: HoneywellSansWeb, sans-serif !important; 
}
.answerBorderShow{
  border-right: 1.5px solid #d0d0d0;
  border-bottom: 1.5px solid #d0d0d0;
  border-left: 1.5px solid #d0d0d0;
  border-radius: 8px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  padding: 9px;
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 14px;
    font-weight: 500;
}
.iconContainer.minus {
  font-family: HoneywellSansWeb, sans-serif !important;
  background-image: url(./images/svgLatest/minus.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.iconContainer.plus {
  background-size: contain;
  font-family: HoneywellSansWeb, sans-serif !important;
  background-image: url(./images/svgLatest/plus.svg);
  background-repeat: no-repeat;

}
.colorWhite{
  color:#fff !important;
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}
.colorBlack{
  color:#303030 !important;
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}
.faqItemBox{
  border:0 none !important;
color:#303030 !important;
width: 100%;
margin: 0 !important;
padding: 0 !important;
position: relative;
}
.faqItemBox p{
 margin: 0 0.5rem 0 0;
}
.answerLists ul{
  font-family: HoneywellSansWeb, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}
.gridDisplay{
  display: grid;
}
.gridDisplay p{
  font-size: 13px;
  font-weight: 500;
  margin: 0 !important;
}
.englishMessage{
  margin:10px 0;
  font-style: italic;
}
.englishMessage p{
  display: inline;
}
.linkSpan{
  margin:0 15px;
  cursor:pointer;
  color:#007BC2;
  text-decoration:underline;
  font-size: 13px;
}
#laLanguageDropdown{
  width:100%;
}
.displayFlex {
  display: flex;
}
.noMargin{
  margin: 0;
}
.translatedAIStyle{
  margin: 3px 0;
  font-style:oblique;
  font-weight:600
}
.agentNameStyle{
  margin: 0; 
  font-size: 17px
}