/* 
.pulse {
    display: block;
    overflow: hidden;
    z-index: 1000;
    border-radius: 50%;
    animation: animatepulse 3s linear infinite;
    cursor: pointer;
}


@keyframes animatepulse {
    0% {
        box-shadow: 0 0 0 0 rgba(14,100,161,.7), 0 0 0 0 rgba(14,100,161,.7);
    }

    40% {
        box-shadow: 0 0 0 30px rgba(14,100,161,.1.5),0 0 0 0 rgba(14,100,161,.6);
    }

    80% {
        box-shadow: 0 0 0 rgba(14,100,161,.0), 0 0 0 20px rgba(14,100,161,0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(14,100,161,.0), 0 0 0 20px rgba(14,100,161,0);
    }
} */

.pinkBg {
    background-color: rgba(14,100,161,.7)!important;
    background-image: linear-gradient(90deg, rgba(14,100,161,.7), rgba(65, 165, 236, 0.7));
}
.intro-banner-vdo-play-btn{
    /* height:60px;
    width:60px; */
    /* position:absolute;
    right: 50%;
    bottom: 50%; */
    text-align:center;
    border-radius:100px;
    z-index:1
}
.intro-banner-vdo-play-btn div{
    margin: 0px 8px 36px 0px;
    /* box-shadow: 0 0 0 0 rgba(14,100,161,.0), 0 0 0 20px rgba(14,100,161,0.8);
    background-color: transparent; */
}
.intro-banner-vdo-play-btn .pulse{
    position:absolute;
    width:160px;
    height:160px;
    z-index:-1;
   right: 0%;
   bottom: 0%;
    opacity:0;
    border-radius:100px;
    -webkit-animation:pulse 6s infinite;
    animation:pulse 6s infinite
}

.pulse-1{
    position:absolute;
    width:160px;
    height:160px;
    z-index:-1;
    right: 0%;
    bottom: 0%;
    opacity:1;
    border-radius:100px;
    transform: scale(0.4);
    box-shadow: 0px 0px 40px 0px rgba(64, 65, 65, 0.6), 0px 0px 40px 0px rgba(105, 105, 105, 0.3);
}

@-webkit-keyframes pulse{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
@keyframes pulse{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
.intro-banner-vdo-play-btn .pulse:nth-child(2){
    animation-delay:.6s;
    -webkit-animation-delay:.6s
}
.intro-banner-vdo-play-btn .pulse:nth-child(3){
    animation-delay:1.2s;
    -webkit-animation-delay:1.2s
}



